.partner {
  .head-row {
    position: relative;

    .type-label {
      color: $lightgrey;
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 5px;
    }

    .page-title {
      font-size: 30px;
      display: inline-block;
      margin: 0;
    }

    .type-badge {
      max-height: 33px;

      @media (min-width: $screen-xs-min) {
        margin-top: -40px;
      }
    }
  }

  .main-container {
    @media (min-width: $screen-sm-min) {
      width: calc(100% - 280px);
      min-height: 1px;
      float: left;

      .description {
        max-width: 90%;
      }
    }

    .logo {
      margin: 15px auto;

      @media (min-width: $screen-sm-min) {
        margin: 25px 0;
      }
    }
  }

  .person-container {
    @media (min-width: $screen-sm-min) {
      width: 280px;
      float: left;
      padding: 10px 0;
      padding-left: 20px;
    }

    .contact-person-wrapper {
      margin: 0 auto;

      @media (min-width: $screen-sm-min) {
        margin: 0;
      }
    }
  }

  .map-container {
    margin-top: 30px;

    .google-map-canvas {
      height: 300px;
    }
  }
}
