.tabs{
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;
    &::after, &::before{
        content: '';
        display: table;
    }
    &::after{
        clear: both;
    }
    .nav-tabs-justified>.active>a, .nav-tabs-justified>.active>a:focus, .nav-tabs-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:focus, .nav-tabs.nav-justified>.active>a:hover{
        border: none;
    }
    .tab-pane.active{
        padding-left: 15px;
        padding-right: 15px;
        .neos-contentcollection{
            padding: 40px 20px 80px 20px;
            background-color: $article-background;
        }
    }
    .nav-stacked + .tab-collection{
        .tab-pane.active{
            padding: 0px;
            background-color: $article-background;
            @media(min-width: $screen-md-min){
                margin-left: -30px;
            }
        }
    }
    .nav-stacked li.active a, .nav-stacked li.active a{
        border-bottom: 2px solid white;
    }
    .nav{
        border: none;
        padding-left: 15px;
        padding-right: 15px;
        li, li.active{
            a{
                border: none;
                background-color: $blue;
                color: white;
                border-radius: 0px;
                margin-right: 0px;
                font-size: 18px;
                &::before{
                    content: ''!important;
                }
            }
        }
        li.active{
            a{
                background-color: $article-background;
                color: $text-color;
            }
        }
        li:hover a{
            background-color: $blue;
            color: $text-color;
        }
        li.active:hover a{
                background-color: $article-background;
                color: $text-color;
        }
    }
}