.tradeArea{
  .contact-person-wrapper {
    float: none;
    margin: 0 auto;
    padding-bottom: 80px;
    @media(max-width:$screen-sm-min){
      &::after{
        position: absolute;
        content: '';
        bottom: 0px;
        top: auto;
        height: 0px;
        width: calc(100% - 30px);
        border-bottom: 2px solid #95989a;
        border-radius: 2px;
        left: 15px;
        margin-bottom: 30px;
      }
    }
  }
  .head-row {
    .page-title {
      display: inline-block;
      margin: 0;
      font-weight: bold;
    }
  }
  .main-container{
    .description{
      padding-top: 25px;
      padding-bottom: 75px;
      text-align: justify;
      color: $grey;
    }

    .detail-list {
      color: $lightgrey;
      border-top: 2px solid $lightgrey;
      border-bottom: 2px solid $lightgrey;
      border-radius: 2px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 25px;
      font-size: 20px;

      & > .row > .col-xs-6:first-of-type {
        text-transform: uppercase;
      }
    }
  }
}
