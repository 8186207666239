.obis-site-extensions-textcolor-red-rule {
  color: $red;
}

.obis-site-extensions-textcolor-green-rule {
  color: $green;
}

.obis-site-extensions-textcolor-blue-rule {
  color: $blue;
}

.obis-site-extensions-textcolor-yellow-rule {
  color: $yellow;
}
