body.color-red{
  .form-control, .select-default-text, .select-wrapper i, .actions .pull-left li.next.submit button.btn-default:hover{
    color: $red;
  }
}
body.color-blue{
  .form-control, .select-default-text, .select-wrapper i, .actions .pull-left li.next.submit button.btn-default:hover{
    color: $blue;
  }
}
body.color-green{
  .form-control, .select-default-text, .select-wrapper i,.actions .pull-left li.next.submit button.btn-default:hover{
    color: $green;
  }
}
body.color-yellow{
  .form-control, .select-default-text, .select-wrapper i, .actions .pull-left li.next.submit button.btn-default:hover{
    color: $yellow;
  }
}
body.color-unset{
  .form-control, .select-default-text, .select-wrapper i,.actions .pull-left li.next.submit button.btn-default:hover{
    color: $blue;
  }
}
body.color-red{
  .gender-switch-wrapper .gender-switch-label .gender-switch-span{
    border-color: $red;
  }
}
body.color-blue{
  .gender-switch-wrapper .gender-switch-label .gender-switch-span{
    border-color: $blue;
  }
}
body.color-green{
  .gender-switch-wrapper .gender-switch-label .gender-switch-span{
    border-color: $green;
  }
}
body.color-yellow{
  .gender-switch-wrapper .gender-switch-label .gender-switch-span{
    border-color: $yellow;
  }
}
body.color-unset{
  .gender-switch-wrapper .gender-switch-label .gender-switch-span{
    border-color: $blue;
  }
}

body.color-red{
  .checkbox input:checked + .checkbox-span::after{
    background-color: $red;
  }
}
body.color-blue{
  .checkbox input:checked + .checkbox-span::after{
    background-color: $blue;
  }
}
body.color-green{
  .checkbox input:checked + .checkbox-span::after{
    background-color: $green;
  }
}
body.color-yellow{
  .checkbox input:checked + .checkbox-span::after{
    background-color: $yellow;
  }
}
body.color-unset{
  .checkbox input:checked + .checkbox-span::after{
    background-color: $blue;
  }
}

//Some css for form in col-sm-6
.neos-contentcollection.col-sm-6{
  form{
    @media(max-width: 1300px){
      #contact-form-section-one{
        width: 33.3333%;
      }
      #contact-form-section-two{
        width: 33.3333%;
      }
      #contact-form-section-four{
        width: 33.3333%;
      }
    }
    @media(max-width: $screen-lg-min){
      #contact-form-section-one{
        width: 100%;
      }
      #contact-form-section-two{
        width: 50%;
      }
      #contact-form-section-four{
        width: 50%;
      }
    }
  }
}

form{
  margin-bottom: 20px;
  .form-control{
    border: none;
    box-shadow: none;
    border-radius: 0px;
    background-color: $article-background;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .form-control:focus{
    border: 0px solid;
    box-shadow: none;
    outline: none;
  }
  .required{
    display: none;
  }
  textarea{
    resize: none;
  }
  .control-label{
    color: $text-color;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
  }
  #contact-form-section-five{
    clear: both;
  }   //Part for SingleSelectDropdown
  .select-wrapper{
    background-color: $article-background;
    margin-bottom: 10px;
    height: 34px;
    padding: 6px 12px;
    padding-top: 8px;
    overflow: hidden;
    position: relative;
    z-index: 100;
    i{
      float: right;
      font-size:  1.5em;
      margin-top: -6px;
      margin-right: -12px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 5px;
        height: 34px;
        left: -5px;
        top: 0px;
        z-index: 102;
        background-color: white;
      }
    }
    select{
      margin:0px;
      padding:0px;
      opacity:0;
      filter:alpha(opacity=0);
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display:block;
      z-index:101;
      cursor: pointer;
      &::focus{
        outline: none;
      }
    }
    .select-default-text{
      font-size: 14px;
      font-weight: bold;
    }
  }
  //genderswitcher
  .gender-switch-wrapper{
    position: relative;
    width: 150px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    #contact-form-gender {
      display: none;
    }
    .gender-switch-label{
      display: block;
      overflow: hidden;
      cursor: pointer;
      border-radius: 20px;
      background-color: $article-background;
      height: 34px;
      .label-inner{
        display: block; width: 200%; margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &::before, &::after{
          display: block; float: left; width: 50%; height: 34px; padding: 0; line-height: 34px;
          box-sizing: border-box;
        }
        &::before{
          content: "Weiblich";
          padding-left: 20px;
          font-size: 16px;
        }
        &::after{
          content: "Männlich";
          padding-right: 20px;
          text-align: right;
          font-size: 16px;
        }
      }
      .gender-switch-span{
        display: block;
        width: 34px;
        background: #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 116px;
        border: 6px solid $blue; border-radius: 300px;
        transition: all 0.3s ease-in 0s;
        &::after{
          @include glyphicons('\e004');
          position: absolute;
          font-size: 1em;
          left: 3px;
          top: 3px;
        }
      }
    }
    #contact-form-gender:checked + .gender-switch-label .label-inner {
      margin-left: 0;
    }
    #contact-form-gender:checked + .gender-switch-label .gender-switch-span {
      right: 0px;
    }
  }
  //checkboxes
  .checkbox{
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    margin-bottom: 10px !important;
    input{
      display: none;
    }
    label{
      height: 34px;
      line-height: 34px;
    }
    .checkbox-span{
      display: block;
      float: right;
      width: 25px;
      height: 25px;
      border: 1px solid $text-color;
      border-radius: 360px;
      box-shadow: 0px 0px 0px 4.5px $article-background;
      background-color: white;
      position: relative;
      margin-left: 20px;
      margin-top: 4.5px;
    }
    input:checked + .checkbox-span::after{
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: 4px;
      top: 4px;
      background-color: $blue;
      border-radius: 100%;
    }
    .checkbox-wrapper{
      label{
        padding: 0px;
        font-size: 16px;
      }
    }
    @media(max-width: $screen-sm-min){
      .checkbox-wrapper{
        text-align: center;
      }
    }

  }
}
.actions{
  .pull-left{
    float: none !important;
    width: 100%;
    li.next.submit{
      width: 100%;
      float: none;
      padding: 0 15px;

      button.btn-default{
        width: 100%;
        display: block;

        background-color: $article-background;
        border-radius: 0;
        border: none;
        font-weight: bold;
        transition: all .5s;
        color: $lightgrey;
        &:hover{
          color: $blue;
          background-color: $article-background;
          transition: all .5s;
        }
      }
    }
  }
}
