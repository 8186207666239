.obis-site-iframe {
  position: relative;
  min-height: 100px;
  transition: min-height 0.5s ease-in-out;

  > .frame {
    
  }

  > .gdpr-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 33px;
    border: 1px solid black;

    > .preview-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(5px);
    }
    
    > .inner {
      max-width: 66%;
      min-width: 400px;
      margin: 0 auto;
      margin-top: 5px;
      font-size: 12pt;
      padding: 25px;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 1px 1px 3px black;

      > h3.title {
        margin-top: 5px;
      }

      > button.grant-consent-button {
        max-width: 90%;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }

  &.has-consented {
    > .gdpr-notice {
      display: none;
    }
  }

  &:not(.has-consented) {
    min-height: 500px;

    > .frame {
      display: none;
    }
  }
}