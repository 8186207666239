.navigation-component {
  & > .inner {
    .navigation-category {
      padding: 0 7px;
      overflow: hidden;
      float: left;
      display: block;
      width: 50%;
      height: auto;
      min-height: 180px;

      @media (min-width: $screen-xs-min) {
        width: 33%;
      }

      @media (min-width: $screen-sm-min) {
        width: 20%;
        min-height: initial;
      }

      h5 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        color: $heading-color;
        white-space: nowrap;

        &::after {
          content: '';
          display: block;
          width: 75px;
          height: 2px;
          background: $lightgrey;
          border-radius: 2px;
          margin-top: 10px;
        }
      }

      .navigation-item {
        margin-bottom: 10px;

        a::before {
          font-size: inherit;
          letter-spacing: 2px;
        }
      }

      @media (min-width: $screen-md-min) {
        &:first-of-type {
          padding-left: 25px;
        }

        &:last-of-type {
          padding-right: 25px;
        }
      }
    }
  }
}
