a {
  color: inherit;
  transition: color 0.2s ease 0s;

  &:hover {
    text-decoration: none;
    color: $text-color;

    &::before {
      color: $text-color;
    }
  }

  &::before {
    letter-spacing: 5px;
    color: $lightgrey;
    font-size: 1.2em;
    transition: color 0.2s ease 0s;
  }

  // Link icons
  &:not(.no-icon) {
    // Externe Links
    &.type-external {
      &::before {
        @include glyphicons;
        content: '\E389\007C';
      }
    }

    // Interne Links
    &.type-internal {
      &::before {
        @include glyphicons;
        content: '\E037\007C';
      }
    }

    // Resource Links
    &.type-resource {
      &::before {
        @include glyph-fileicons;
        content: '\E140\007C';
      }

      &[href$='.txt'] {
        &::before {
          content: '\E001\007C';
        }
      }
      &[href$='.doc'] {
        &::before {
          content: '\E002\007C';
        }
      }
      &[href$='.docx'] {
        &::before {
          content: '\E010\007C';
        }
      }
      &[href$='.csv'] {
        &::before {
          content: '\E012\007C';
        }
      }
      &[href$='.pps'] {
        &::before {
          content: '\E017\007C';
        }
      }
      &[href$='.ppt'] {
        &::before {
          content: '\E019\007C';
        }
      }
      &[href$='.pptx'] {
        &::before {
          content: '\E020\007C';
        }
      }
      &[href$='.png'] {
        &::before {
          content: '\E049\007C';
        }
      }
      &[href$='.jpg'], &[href$='.jpeg'] {
        &::before {
          content: '\E052\007C';
        }
      }
      &[href$='.pdf'] {
        &::before {
          content: '\E065\007C';
        }
      }
    }
  }
}
