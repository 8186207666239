.articles {
  .description {
    color: $grey;
    margin-bottom: 75px;
    width: 100%;
  }

  .preview-wrapper {
    background-color: $article-background;
    padding: 10px 30px;
    margin-bottom: 15px;

    .row {
      display: flex;
      align-items: center;
    }

    .article-preview-title {
      color: $grey;
      font-weight: bold;
      font-size: 20px;
      margin: 0px;
    }

    .article-preview-description {
      padding-top: 10px;
      padding-bottom: 50px;
    }

    .details-article-button {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 5px 10px;
      border: 1px solid #b2b2b2;
      color: $grey;
      text-transform: uppercase;
      font-size: 12px;
      background-color: $article-background;

      &::before {
        content: '';
      }
    }

    .content-wrapper-article {
      position: relative;
      min-height: 150px;
    }
  }

  .date-article {
    align-self: flex-start;

    span {
      width: 100%;
      text-align: right;
      color: $grey;
      font-size: 4em;
      display: block;
      line-height: 0.7;
    }
  }

  .icon-wrapper {
    display: inline-block;
    padding: 22px 0;
    border-radius: 100%;
    background-color: white;
    margin: 0 auto;

    &.has-end-date {
      padding: 17px 0;

      span {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    i {
      color: $grey;
      font-size: 3em;
      width: 100%;
      text-align: center;
    }

    span {
      width: 100%;
      text-align: center;
      display: block;
      color: $grey;
      font-weight: bold;
      font-size: 18px;
      padding-top: 1px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .preview-article-image,
  .date-article {
    display: none;
  }

  .neos-widget-paginator {
    clear: both;
    list-style: none;
    padding: 0px;
    padding: 0px 15px;
    margin: 0px;
    text-align: right;

    li:first-child {
      clear: both;

      &::before {
        display: none !important;
      }
    }

    .previous+li {
      color: brown;

      &::before {
        display: none !important;
      }
    }

    li,
    li.current {
      color: $grey;
      font-size: 22px;
      display: inline-block;
      position: relative;
      padding-left: 0.5em;
      padding-right: 0.5em;

      a {
        color: $grey;
        font-size: 22px;
        text-decoration: none;
      }

      a:focus {
        text-decoration: none;
      }

      &::before {
        @include fontawesome('\f111');
        color: $text-color;
        font-size: 0.5em;
        position: absolute;
        top: calc(50% - 0.5em);
        left: -0.5em;
      }
    }

    .nothing-found-notice {
      text-align: center;
      font-size: 13pt;
      padding: 30px 15px;
    }

    .next {
      display: none;
    }

    .previous {
      display: none;
    }

    a::before {
      content: '';
    }
  }

  @media(min-width: $screen-sm-min) {

    .preview-article-image,
    .date-article {
      display: block;
    }

    .description {
      width: 50%;
    }

    .article-preview-description {
      padding-bottom: 0px;
    }
  }

  .filter-buttons {
    text-align: right;

    .filter-button {
      display: inline-block;
      padding: 10px 10px 7px;
      background: white;
      color: $text-color;
      font-weight: lighter;
      margin-right: 7px;
      text-transform: uppercase;

      .glyphicons::before {
        padding: 0;
      }

      &.active {
        background: $text-color;
        color: white;
      }
    }
  }

  .article-pinned-icon {
    position: absolute;
    right: 15px;
    top: 3px;
    font-size: 15pt;
    opacity: 0.4;
    color: black;
  }
}

body.color-red .articles .neos-widget-paginator li::before {
  color: $red;
}

body.color-blue .articles .neos-widget-paginator li::before {
  color: $blue;
}

body.color-green .articles .neos-widget-paginator li::before {
  color: $green;
}

body.color-yellow .articles .neos-widget-paginator li::before {
  color: $yellow;
}
