.teaser-box {
  margin-bottom: 10px;

  & > a > .inner {
    .pseudo-image, .video-header, .contents {
      border: 1px solid $lightgrey;
    }

    .pseudo-image {
      border-bottom: none;
      padding-bottom: 100% / 3 * 2;
      overflow: hidden;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .video-header {
      .jonnitto-plyr-container {
        margin-bottom: 0;
        height: 100%;
      }
    }

    .contents {
      padding: 20px;

      .teaser-title {
        margin-top: 0;
        font-weight: bold;

        &::after {
          content: '';
          display: block;
          width: 75px;
          height: 2px;
          background: $lightgrey;
          border-radius: 2px;
          margin-top: 10px;
        }
      }

      .teaser-introduction {
        color: $text-color;
      }
    }
  }

  &.color-unset > a > .inner {
    .contents {
      .teaser-title {
        color: $heading-color;
      }
    }
  }

  &.color-blue > a > .inner {
    .contents {
      .teaser-title {
        color: $blue;
      }
    }
  }

  &.color-red > a > .inner {
    .contents {
      .teaser-title {
        color: $red;
      }
    }
  }

  &.color-green > a > .inner {
    .contents {
      .teaser-title {
        color: $green;
      }
    }
  }

  &.color-yellow > a > .inner {
    .contents {
      .teaser-title {
        color: $yellow;
      }
    }
  }
}
