.contact-person-wrapper {
  max-width: 300px;
  color: $text-color;
  padding: 15px 10px;

  @media (min-width: $screen-md-min) {
    padding: 15px 20px 15px 10px;
  }

  .contact-person-image-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;

    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-sm-min) {
      margin-right: 0;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .contact-person-image {
      height: 100%;
      width: auto;
    }
  }

  .contact-person-icon-wrapper {
    .contact-person-icon {
      align-items: center;
      background: $text-color;
      border-radius: 50%;
      color: white;
      display: flex;
      flex-direction: row;
      font-size: 23px;
      height: 50px;
      justify-content: center;
      margin-left: auto;
      margin-right: 15px;
      width: 50px;
    }
  }

  .contact-person-name {
    font-size: 21px;
    margin: 5px 0;
    margin-top: 15px;
  }

  .contact-person-position {
    font-size: 18px;
    margin-left: 20px;
  }

  .contact-person-divider {
    width: 33%;
    height: 2px;
    border-radius: 2px;
    background: $lightgrey;
    margin: 15px 0;
  }

  & > a {
    display: block;
    margin: 2px;

    &.contact-person-phone-link::before {
      @include glyphicons('\e443');
    }

    &.contact-person-fax-link::before {
      @include glyphicons('\e450');
    }

    &.contact-person-mail-link::before {
      @include glyphicons('\e011');
    }

    &.contact-person-web-link::before {
      @include glyphicons('\e341');
    }

    &::before {
      margin-top: 3px;
      font-size: 14px;
      color: $text-color;
    }
  }

  .contact-person-socials > a {
    font-size: 18px;
    margin-right: 4px;
  }
}

body.color-blue {
  .contact-person-wrapper {
    .contact-person-name {
      color: $blue;
    }

    .contact-person-icon {
      background: $blue;
    }
  }
}

body.color-red {
  .contact-person-wrapper {
    .contact-person-name, .contact-person-icon {
      color: $red;
    }

    .contact-person-icon {
      background: $red;
    }
  }
}

body.color-green {
  .contact-person-wrapper {
    .contact-person-name, .contact-person-icon {
      color: $green;
    }

    .contact-person-icon {
      background: $green;
    }
  }
}

body.color-yellow {
  .contact-person-wrapper {
    .contact-person-name, .contact-person-icon {
      color: $yellow;
    }

    .contact-person-icon {
      background: $yellow;
    }
  }
}
