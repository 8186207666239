.social-button {
    position: relative;
    height: 22px;
    width: auto;

    color: white;
    border-radius: 3px;
    padding: 3px 7px;

    transition: background-color 0.3s ease;

    &:not(.no-label) > .fa {
        margin-right: 4px;
    }

    &.no-label {
        height: 33px;
        width: 45px;
        font-size: 23px;
        text-align: center;
    }

    & > a {
        color: white !important;
        text-decoration: none !important;
    }

    &.mocked-share-button {
        cursor: pointer;
    }

    // Button specs

    &.facebook-share-button {
        @include button-background(#3b5998);
    }

    &.twitter-share-button {
        @include button-background(#1da1f2);
    }

    &.google-plus-share-button {
        @include button-background(#dd4b39);
    }

    &.whatsapp-share-button {
        @include button-background(#25d366);
    }

    &.email-share-button {
        @include button-background(#999999);
    }
}