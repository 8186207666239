header {
  nav.navbar {
    top: 0;
    width: 100%;
    z-index: 1000;

    & > .inner {
      background: white;
      border-radius: 0;
      box-shadow: 1px 1px 7px -1px $grey;
      height: 60px;
      padding: 5px 15px;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;

      @media (min-width: $screen-sm-min) {
        position: relative;
        margin: 20px auto 0;
      }

      .logo-wrapper {
        .logo {
          max-height: 48px;
          width: auto;
        }
      }

      button.navigation-toggle {
        padding: 13px;
      }

      .main-navigation {
        background: rgba(255,255,255,0.9);
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 999;
        padding: 0;
        box-shadow: 1px 1px 7px -1px $grey;
        overflow: hidden;

        height: 0;
        transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;

        &.in {
          padding: 30px 0;
        }
      }
    }
  }

  .video-header {
    max-height: 600px;
    overflow: hidden;

    .jonnitto-plyr-container {
      margin-bottom: 0;

      @media (max-width: $screen-sm-max) {
        margin-top: 50px;
      }

      video.neos-plyr {
        max-height: 600px;
      }
    }
  }

  .static-header-image {
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

body:not(.neos-backend) header nav.navbar {
  position: absolute;
}
