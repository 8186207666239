body.color-blue main {
  .neos-nodetypes-headline, h1, h2, h3, h4, h5, h6 {
    color: $blue;
  }
}

body.color-red main {
  .neos-nodetypes-headline, h1, h2, h3, h4, h5, h6 {
    color: $red;
  }
}

body.color-green main {
  .neos-nodetypes-headline, h1, h2, h3, h4, h5, h6 {
    color: $green;
  }
}

body.color-yellow main {
  .neos-nodetypes-headline, h1, h2, h3, h4, h5, h6 {
    color: $yellow;
  }
}

.neos-nodetypes-headline {
  h1, h2, h3, h4, h5, h6, p {
    span.inline-color-rule-blue {
      color: $blue;
    }

    span.inline-color-rule-green {
      color: $green;
    }

    span.inline-color-rule-red {
      color: $red;
    }

    span.inline-color-rule-yellow {
      color: $yellow;
    }
  }
}
h2.sub-title{
  color: $lightgrey !important;
  margin: 0;
  font-size: 20px;
  font-weight: normal;
}
h1::after {
  content: '';
  display: block;
  width: 75px;
  height: 2px;
  background: $lightgrey;
  border-radius: 2px;
  margin-top: 10px;
}
h1{
  font-weight: bold;
}
