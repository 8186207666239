.social-icons-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;

  .social-icon {
    background-color: white;
    height: 30px;
    width: 30px;
    margin: 15px;
    overflow: hidden;
    transform: scale(1);
    transition: filter 0.5s ease-in-out, transform 0.5s ease-in-out;

    &:hover {
      filter: grayscale(0);
      transform: scale(1.1);
    }
  }

  #social-icon-facebook {
    border-radius: 2px;
  }

  #social-icon-twitter {
    border-radius: 3px;
  }

  #social-icon-instagram {
    border-radius: 10px;
  }

  @media (min-width: $screen-sm-min) {
    display: block;
    position: absolute;

    .social-icon {
      filter: grayscale(1);
    }
  }
}
