body.color-red{
  .panel-group.neos-contentcollection{
    .panel-heading{
      border-bottom-color: $red;
    }
    .panel-default>.panel-heading+.panel-collapse>.panel-body{
      border-bottom-color: $red;
    }
  }
}
body.color-blue{
  .panel-group.neos-contentcollection{
    .panel-heading{
      border-bottom-color:$blue;
    }
    .panel-default>.panel-heading+.panel-collapse>.panel-body{
      border-bottom-color: $blue;
    }
  }
}
body.color-green{
  .panel-group.neos-contentcollection{
    .panel-heading{
      border-bottom-color: $green;
    }
    .panel-default>.panel-heading+.panel-collapse>.panel-body{
      border-bottom-color: $green;
    }
  }
}
body.color-yellow{
  .panel-group.neos-contentcollection{
    .panel-heading{
      border-bottom-color: $yellow;
    }
    .panel-default>.panel-heading+.panel-collapse>.panel-body{
      border-bottom-color: $yellow;
    }
  }
}
body.color-unset{
  .panel-group.neos-contentcollection{
    .panel-heading{
      border-bottom-color: $blue;
    }
    .panel-default>.panel-heading+.panel-collapse>.panel-body{
      border-bottom-color: $blue;
    }
  }
}
.panel-group.neos-contentcollection{
  padding-top: 15px;
  .panel-title{
    font-size: 18px;
    color:$text-color;
    position:relative;
    a{
      text-decoration: none;
      &.collapsed::before{
        transform: rotate(90deg);
        transition: all 0.5s;
      }
      &::before{
        content:'';
        position: absolute;
        top: calc(50% - 2px);
        right: 15px;
        width: 15px;
        height: 4px;
        background-color: $lightgrey;
        transform: rotate(0deg);
        transition: all 0.5s;
      }
      &::after{
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        right: 15px;
        width: 15px;
        height: 4px;
        background-color: $lightgrey;
      }
    }
  }
  .panel-heading{
    padding:0px;
    margin: 0px;
    padding-bottom: 20px;
    background-color: white;
    border: none;
    border-bottom: 2px solid $grey;
    cursor: pointer;
  }
  .panel{
    margin-bottom: 25px;
    border: none;
    box-shadow: none;
  }
  .panel-default>.panel-heading+.panel-collapse>.panel-body{
    border-top: 0px;
    margin: 0px;
    padding: 15px 0px;
    background-clip: content-box;
    background-color:  $article-background;
    border-bottom: 2px solid $grey;
    .neos-contentcollection{
      padding: 15px;
      p{
        margin: 0px;
      }
    }
  }

}
