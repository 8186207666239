// Bootstrap
$icon-font-path: '../Fonts/bootstrap/';

@import 'bootstrap-sprockets';
@import 'bootstrap';

// Font Awesome
$fa-font-path: '../Fonts';

@import 'font-awesome';

// CSS Hamburgers
$hamburger-layer-width: 27px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $heading-color;
$hamburger-hover-opacity: 1;

$hamburger-types: (
  collapse,
  collapse-r
);

@import 'hamburgers';

// Swipebox
#swipebox-slider {
  .slide-loading {
		background: url(../Images/loader.gif) no-repeat center center;
	}
}

#swipebox-close {
	background-image: url(../Images/icons.png);
}

// Fonts
@font-face {
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/TheSans-3-Light.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/TheSans-3-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/TheSans-3-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/TheSans-3-Light.woff') format('woff'), /* Pretty Modern Browsers */
       url('../Fonts/TheSans-3-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/TheSans-3-Light.svg#TheSans-Light') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 500;
  src: url('../Fonts/TheSans-5-Regular.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/TheSans-5-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/TheSans-5-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/TheSans-5-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../Fonts/TheSans-5-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/TheSans-5-Regular.svg#TheSans-Regular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/TheSans-7-Bold.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/TheSans-7-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/TheSans-7-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/TheSans-7-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../Fonts/TheSans-7-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/TheSans-7-Bold.svg#TheSans-Bold') format('svg'); /* Legacy iOS */
}


// Bootstrap hidden classes workaround
@media (max-width: $screen-xs-max) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: $screen-lg-min) {
  .hidden-xl {
    display: none !important;
  }
}
