html {
  body {
    font-family: 'TheSans', sans-serif;

    button {
      outline: none;
    }

    @media (min-width: $screen-sm-min) {
      .container {
        max-width: 90vw;
      }
    }

    .no-padding {
      padding: 0;
    }

    .neos-nodetypes-text, p {
      color: $text-color;
    }

    main {
      padding: 10px 0;

      @media (min-width: $screen-md-min) {
        padding: 25px 0 15px;
      }

      @media (min-width: $screen-lg-min) {
        padding: 35px 0 15px;
      }
    }

    &.no-scroll {
      overflow: hidden !important;
    }
  }
}
