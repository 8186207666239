.details-article-page-button{
  padding: 5px 10px;
  border: 1px solid #b2b2b2;
  color: $text-color;
  text-transform: uppercase;
  font-size: 12px;
  background-color: $article-background;
  width: 100px;
  margin-left: auto;
  display: block;
  text-align: center;

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &::before{
    content: ''!important;
  }

  &:hover {
    background-color: $text-color;
    color: white;
  }

  .no-pad::before {
    padding: 2px;
  }
}
