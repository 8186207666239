.tradeAreaGallery-reference{
  .short-info-ul li{
    font-size: 14px;
    text-align: left;
  }
  .details-button{
    float: left;
    background-color: white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    color: $text-color;

    &:hover {
      background-color: $shadow-color;
      color: white;
    }
  }
  @media(min-width: $screen-sm-min){
    .short-info-ul li{
      font-size: 14px;
      text-align: center;
    }
  }
}
