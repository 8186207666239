.partner-element {
  $ratio-width-xs: 100%;
  $ratio-width-sm: 50%;
  $ratio-width-md: 100%/3;

  $ratio-height-xs: $ratio-width-xs/2;
  $ratio-height-sm: $ratio-width-sm/2;
  $ratio-height-md: $ratio-width-md/1.7;

  width: calc(#{$ratio-width-xs} - 30px);

  padding: 0;
  padding-bottom: $ratio-height-xs;

  max-width: 500px;
  max-height: 250px;

  @media (min-width: $screen-sm-min) {
    width: calc(#{$ratio-width-sm} - 30px);
    padding-bottom: $ratio-height-sm;
  }

  @media (min-width: $screen-md-min) {
    width: calc(#{$ratio-width-md} - 30px);
    padding-bottom: $ratio-height-md;
  }

  margin: 15px;

  border: 1px solid $lightgrey;

  .inner {
    width: 100%;

    .main {
      @include absolute-block-padding(2%);

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      transform: rotateY(0deg);
      backface-visibility: hidden;

      transition: transform 0.3s ease-in-out;

      .logo {
        max-height: 100%;
      }
    }

    .flip {
      @include absolute-block;

      padding: 20px;
      overflow: hidden;

      background-color: $middlegrey;

      transform: rotateY(180deg);
      backface-visibility: hidden;

      transition: transform 0.3s ease-in-out;

      .type-badge {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 30px;
        height: 30px;
      }

      .contact-person-name {
        font-size: 18px;
      }

      .contact-person-position {
        margin-left: 10px;
      }

      .divider {
        width: 33%;
        height: 2px;
        border-radius: 2px;
        background: $lightgrey;
        margin: 5px 0;

        @media (min-width: $screen-sm-min) {
          margin: 15px 0;
        }
      }

      & > a {
        display: block;
        cursor: pointer;
        color: inherit;

        &::before {
          font-size: 95%;
          margin-top: 1%;
        }
      }

      .contact-person-phone-link::before {
        @include glyphicons('\e443');
      }

      .contact-person-fax-link::before {
        @include glyphicons('\e450');
      }

      .contact-person-mail-link::before {
        @include glyphicons('\e011');
      }

      .contact-person-web-link::before {
        @include glyphicons('\e341');
      }

      .more {
        position: absolute;
        right: 20px;
        bottom: 10px;

        & > a {
          color: inherit;
        }
      }
    }
  }

  &:hover {
    .inner {
      .main {
        transform: rotateY(-180deg);
      }

      .flip {
        transform: rotateY(0deg);
      }
    }
  }

  &.type-member {
    .inner > .flip {
      & > .contact-person-name {
        color: $green;
      }

      & > a:hover {
        color: $darkgreen;

        &::before {
          color: $darkgreen;
        }
      }

      & > .more > a:hover {
        color: $darkgreen;
      }
    }
  }

  &.type-partner {
    .inner > .flip {
      & > .contact-person-name {
        color: $yellow;
      }

      & > a:hover {
        color: $darkyellow;

        &::before {
          color: $darkyellow;
        }
      }

      & > .more > a:hover {
        color: $darkyellow;
      }
    }
  }
}
