@mixin fontawesome($content:'') {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: $content;
}

@mixin _glyphicon($content) {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: $content;
}

@mixin glyphicons($content:'') {
  font-family: 'Glyphicons Regular';
  vertical-align: top;

  @include _glyphicon($content);
}

@mixin glyph-fileicons($content:'') {
  font-family: 'Glyphicons Filetypes';
  position: relative;
  top: 1px;

  @include _glyphicon($content);
}

@mixin glyph-halflings($content:'') {
  font-family: 'Glyphicons Halflings';
  position: relative;
  top: 1px;

  @include _glyphicon($content);
}

@mixin glyph-social($content:'') {
  font-family: 'Glyphicons Social';
  position: relative;
  top: 1px;

  @include _glyphicon($content);
}

@mixin absolute-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin absolute-block-padding($padding) {
  position: absolute;
  top: $padding;
  left: $padding;
  right: $padding;
  bottom: $padding;
}

@mixin button-background($color, $darken: 15%) {
  background-color: $color;

  &:hover {
    background-color: darken($color: $color, $amount: $darken);
  }
}

@mixin partial-underline($color, $height: 3px, $width: 30%, $concise: true) {
  &::after {
    content: '';
    display: block;

    height: $height;
    background: $color;
    width: $width;

    @if $concise == true {
      margin-top: -$height;
    }
  }
}

@mixin clearfix {
  &::before, &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin custom-clearfix {
  display: block;
  clear: both;
  content: " ";
}
