header nav.navbar>.inner {
  button#search-trigger-button {
    background: none;
    border: none;
    font-size: 16pt;
    margin-top: -3px;
    padding: 13px;
    color: $text-color;

    transition: color 0.5s ease-in-out;

    &[disabled] {
      cursor: not-allowed;
      color: $lightgrey;
    }
  }

  div.search-box {
    padding: 10px;
    height: 100%;

    &>input {
      height: 100%;
      width: 150px;
      max-width: 500px;

      border: none;
      border-bottom: 1px solid $lightgrey;
      padding: 0 7px;
      outline: none;

      color: $text-color;

      transition: width 0.5s ease-in-out;
    }
  }

  div.search-result-box {
    background: rgba(255, 255, 255, 0.9);
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    padding: 0;
    box-shadow: 1px 1px 7px -1px $grey;
    overflow: hidden;

    height: 0;
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;

    &.loading {
      height: 100px;

      &>.loading-spinner {
        display: block;
      }
    }

    &.finished {
      height: 200px;

      &>.loading-spinner {
        display: none;
      }
    }

    &>.loading-spinner {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 30pt;
      padding: 25px;
    }

    &>.no-results-message {
      display: none;
      height: 100%;
      width: 100%;

      padding: 50px;
      font-size: 12pt;

      & > p {
        margin-bottom: 2px;
      }

      .search-term-box {
        margin: 5px;

        font-size: 13pt;
        font-weight: bold;
        font-style: italic;

        line-height: 1em;
      }
    }

    &.no-results > .no-results-message {
      display: block;
    }

    &>.close-icon-button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px 10px;
      font-size: 13pt;
      color: $text-color;
    }

    & > .actual-results {
      height: 100%;
      overflow-y: scroll;
    }
  }
}