footer {
  border-top: 1px solid $heading-color;

  .upper-row {
    box-shadow: 0 4px 10px -2px $shadow-color;

    .footer-breadcrumb {
      position: relative;
      padding: 10px 15px;
      min-height: 50px;

      .footer-menu {
        margin: 0;
        margin-right: 10%;

        @media (min-width: $screen-sm-min) {
          margin-right: 0;
        }

        .footer-menu-item {
          font-size: 15px;
          line-height: 30px;
          height: 30px;
          margin-right: 15px;

          a {
            color: $heading-color;
            transition: color 0.3s ease-in-out;

            &:hover {
              text-decoration: none;
              color: $darkgrey;
            }
          }
        }
      }

      .scroll-up {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 20px;
        z-index: 1;

        display: block;

        @media (min-width: $screen-sm-min) {
          left: 100%;
          right: initial;
        }

        .scroll-up-inner, .scroll-up-helper {
          bottom: 20px;
          height: 30px;
          position: fixed;
          width: 30px;

          i.fa {
            display: block;
            margin-top: -3px;
          }
        }

        .scroll-up-inner {
          border-radius: 15px;
          border: 1px solid $heading-color;
          color: $heading-color;
          cursor: pointer;
          font-size: 30px;
          text-align: center;
          z-index: 2;
          background: white;

          transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

          &.docked {
            position: static;
          }

          &:hover {
            border-color: $darkgrey;
            color: $darkgrey;
          }
        }
      }
    }
  }

  .lower-row {
    margin: 30px auto;

    .contact-info, .copyright-info {
      width: 100%;

      &:last-of-type {
        margin-top: 30px;
      }

      @media (min-width: $screen-xs-min) {
        width: 50%;
        margin-top: 0;
      }
    }

    .contact-info {
      .fa {
        color: $blue;
        width: 13px;
        font-size: 15px;
        margin-right: 5px;
      }

      a {
        color: $text-color;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: $blue;
          text-decoration: none;
        }
      }
    }

    .copyright-info {
      color: $text-color;
      text-align: right;

      .info-block {
        .year {
          font-size: 37px;
          display: inline-block;
        }

        .logo {
          max-height: 50px;
          width: auto;
          display: inline-block;
          margin-top: -40px;
        }
      }

      .info-text {
        & > div {
          display: inline;
        }
      }
    }
  }
}
