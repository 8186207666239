#neos-inspector {
  i.inspector-icon-color-blue::before {
    color: $blue;
  }

  i.inspector-icon-color-red::before {
    color: $red;
  }

  i.inspector-icon-color-green::before {
    color: $green;
  }

  i.inspector-icon-color-yellow::before {
    color: $yellow;
  }

  i.inspector-icon-color-unset::before {
    color: $heading-color;
  }
}
