.tradeAreaGallery{
  .head-row {
    .page-title {
      display: inline-block;
      margin: 0;
    }
  }
  .main-container{
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .description{
    color: $grey;
  }
  .details-button{
    padding: 5px 30px;
    margin: 0;
    border: 1px solid black;
    color: $text-color;
    background-color: white;
    text-transform: uppercase;
    font-size: 12px;

    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &::before{
      content: '';
    }
    &:focus{
      text-decoration: none;
    }

    &:hover {
      color: white;
      background-color: $text-color;
    }
  }
  .preview-wrapper{
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    .preview-image{
      max-height: 250px;
      margin: 0px auto;
      margin-bottom: 25px;
    }
    &::after{
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  }
  .preview-title{
    font-weight: bolder;
    text-align: left;
    color: $grey;
    &::after{
      content: '';
      display: block;
      width: 75px;
      height: 1px;
      background: #95989a;
      border-radius: 2px;
      margin-top: 10px;
    }
  }
  .short-info-ul{
    list-style: none;
    padding : 0px;
    margin-bottom: 15px;
    li{
      width: 100%;
      text-align: center;
    }
    &::after{
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  }
  @media(min-width:$screen-md-min){
    .short-info-ul{
      li{
        float: left;
        padding-right: 30px;
        position:relative;
        width:auto;
        &::after{
          content: '|';
          right: 15px;
          position: absolute
        }
        &:last-child::after{
          content: '';
        }
      }
    }
    .preview-title{
      &::after{
        margin-top: 20px;
      }
    }
    .details-button{
      float: right;
    }
    .preview-wrapper{
      text-align: none;
    }
  }
  @media(min-width:$screen-lg-min){
    .preview-title{
      &::after{
        margin-top: 30px;
      }
    }
    .details-button{
      padding: 5px 30px;
      margin: 0;
      border: 1px solid black;
      color: black;
      text-transform: uppercase;
      font-size: 16px;
      &::before{
        content: '';
      }
      &:focus{
        text-decoration: none;
      }
    }
    .short-info-ul{
      li{
        font-size: 16px;
      }
    }
  }
}

body.color-red article.tradeAreaGallery .details-button:hover {
  background-color: $red;
}

body.color-green article.tradeAreaGallery .details-button:hover {
  background-color: $green;
}

body.color-blue article.tradeAreaGallery .details-button:hover {
  background-color: $blue;
}

body.color-yellow article.tradeAreaGallery .details-button:hover {
  background-color: $yellow;
}

.tradeAreaGallery {
  .trade-area-item {
    width: 100%;
    padding: 0 15px;
    float: left;

    @media (min-width: $screen-xs-min) /*and (max-width: $screen-sm-max)*/ {
      width: 50%;

      // &:nth-of-type(2n) {
      //   @include clearfix;
      // }
    }

    @media (min-width: $screen-sm-min) {
      width: 33%;

      // &:nth-of-type(3n) {
      //   @include clearfix;
      // }
    }
  }
}
