.partners {
  .head-row {
    position: relative;
    padding-bottom: 30px;

    .search {
      position: absolute;
      right: 0;
      bottom: 0;

      & > input {
        padding: 7px;
        border: 1px solid grey;
        border-right: none;
        float: left;
        height: 35px;
        min-width: 200px;

        &:focus {
          outline: none;
        }
      }

      & > .search-button {
        padding: 5px;
        float: left;
        height: 35px;
        border: 1px solid grey;
        border-left: none;

        & > .glyphicons {
          margin-top: -1px;
          margin-right: -2px;

          &.glyphicons-remove {
            cursor: pointer;
          }
        }
      }
    }
  }

  .map-container {
    margin-top: 10px;

    .google-map-canvas {
      height: 350px;
    }
  }

  .filter-container {
    margin: 10px 0;

    & > * {
      margin: 0 10px;
      vertical-align: top;
      display: inline-block;
    }

    .filter-button {
      cursor: pointer;
      position: relative;
      margin-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        width: 120%;
        height: 1px;
        bottom: -7px;
        left: -5%;

        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }

      &.active::before {
        visibility: visible;
        transform: scaleX(1);
      }

      &[data-filter='.type-member']::before {
        background-color: $green;
      }

      &[data-filter='.type-partner']::before {
        background-color: $yellow;
      }

      & > img {
        vertical-align: top;
      }
    }

    .remove-filters {
      cursor: pointer;
      font-size: 25px;
      width: 15px;

      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.in {
        opacity: 1;
      }
    }
  }

  .nothing-found-notice {
    display: none;

    padding: 30px;
    text-align: center;

    & > p {
      font-weight: bold;
      font-style: italic;
      font-size: 16px;
    }
  }

  .partner-gallery[style*="height: 0px;"] + .nothing-found-notice {
    display: block;
  }
}
