.article {
  .page-title {
    margin-top: 0px;
    &::after {
      margin-bottom: 30px;
    }
  }

  .description {
    color: $grey;
    margin-bottom: 75px;
    width: 50%;
  }

  .date-wrapper {
    text-align: right;
    position: absolute;
    width: 50%;
    top: 0px;
    right: 15px;
    bottom: auto;
    left: auto;

    .icon-wrapper {
      display: inline-block;
      padding: 5px;
      border-radius: 100%;
      background-color: $text-color;

      i {
        color: white;
        font-size: 1.5em;
      }
    }

    .article-date, .article-end-date {
      color: $lightgrey;
      font-size: 18px;
      margin-top: 10px;

      &::after {
        content: '';
        display: block;
        width: 75px;
        height: 2px;
        background: $lightgrey;
        border-radius: 2px;
        position: absolute;
        bottom: -15px;
        right: 0px;
      }
    }
  }

  .date-range-separator {
    padding-right: 20px;
  }

  .neos-contentcollection {
    margin-bottom: 70px;
  }

  .back-button {
    padding: 10px 10px;
    padding-right: 15px;
    border: 1px solid black;
    text-transform: uppercase;
    color: $text-color;
    font-size: 14px;

    i {
      font-size: 1.5em;
      color: $text-color;
      margin-right: 10px;
      margin-top: -8px;
    }

    &::before {
      content: ''
    }
  }

  .repositioning {
    position: initial;
  }

  .relative-repositioning {
    position: relative;
  }

  @media(min-width: $screen-md-min) {
    .repositioning {
      position: relative;
    }
    .relative-repositioning {
      position: initial;
    }
    .date-wrapper {
      top: calc(-1.5em - 30px);
      right: 0px;
      width: 100%;
    }
  }


  .social-buttons-wrapper {
    margin-bottom: 50px;
    text-align: left;

    .social-button {
      float: left;
      margin: 3px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
