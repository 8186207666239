header nav.navbar>.inner {
  .search-result-item {
    padding: 10px;

    // border-bottom: 1px solid $text-color;
    // border-right: 1px solid $text-color;

    .inner {
      line-height: 40px;

      &>.icon-wrapper-circle {
        float: left;
        width: 40px;
        height: 40px;

        margin-right: 10px;

        text-align: center;
        line-height: 40px;

        border-radius: 20px;
        background: $lightgrey;

        &>.icon {
          vertical-align: middle;
          color: white !important;
        }
      }

      &>.text-wrapper {
        float: left;
        width: calc(100% - 50px);

        .pagetitle {
          margin: 0;

          // font-weight: bold;
          font-size: 13pt;
        }

        @include partial-underline($lightgrey);

        &::after {
          transition-property: width;
          transition-duration: 300ms;
          // transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: ease-in-out;

          min-width: 100px;
        }
      }
    }

    a:hover {
      .inner > .text-wrapper::after {
        width: 80%;
      }
    }

    &.color-unset {
      // background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, $blue 100%);

      .inner {
        .text-wrapper {
          // @include partial-underline($blue);
        }
      }
    }

    &.color-blue {

      // background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, $blue 100%);
      .inner {
        .text-wrapper {
          @include partial-underline($blue);
        }

        .icon-wrapper-circle {
          background: $blue;
        }
      }
    }

    &.color-red {

      // background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, $red 100%);
      .inner {
        .text-wrapper {
          @include partial-underline($red);
        }

        .icon-wrapper-circle {
          background: $red;
        }
      }
    }

    &.color-green {

      // background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, $green 100%);
      .inner {
        .text-wrapper {
          @include partial-underline($green);
        }

        .icon-wrapper-circle {
          background: $green;
        }
      }
    }

    &.color-yellow {

      // background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, $yellow 100%);
      .inner {
        .text-wrapper {
          @include partial-underline($yellow);
        }

        .icon-wrapper-circle {
          background: $yellow;
        }
      }
    }
  }
}
