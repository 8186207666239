.google-map-canvas {
  #inner-map-info-window-content {
    position: relative;

    .inner-map-detail-link {
      position: absolute;
      bottom: 0;
      right: -20px;

      font-weight: bold;

      .fa {
        margin-left: 5px;
      }
    }

    .inner-map-detail-logo {
      max-width: 100%;
      height: auto;
      max-height: 90px;

      display: block;
      margin: 0 auto;
    }
  }
}
