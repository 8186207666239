header {
  .swiper-slide {
    position: relative;

    .content {
      position: absolute;
      top: 35%;

      @media (min-width: $screen-sm-min) {
        left: 50%;
      }

      .content-inner {
        position: relative;

        @media (min-width: $screen-sm-min) {
          left: calc(-50% - 30px);
        }

        .neos-nodetypes-headline,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: white;

          &::after {
            background: white;
          }
        }

        .neos-nodetypes-text,
        p {
          color: white;
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      &>img {
        display: none;
      }
    }

    @media (min-width: $screen-sm-min) {
      background: none !important;
    }
  }

}
