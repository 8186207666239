.breadcrumb-wrapper {
  padding: 10px 15px;
  box-shadow: inset 0 -3px 10px -2px $shadow-color;

  .breadcrumb-menu {
    margin: 10px 0;

    .breadcrumb-item {
      color: lighten($heading-color,15%);

      &:not(.no-arrow)::before {
        @include fontawesome('\f105');

        margin-right: 10px;
      }

      a {
        text-transform: uppercase;
        color: $heading-color;
        transition: color 0.3s ease-in-out;

        &[href]:hover {
          text-decoration: none;
          color: lighten($heading-color,25%);
        }
      }

      &.shortcut a {
        color: lighten($heading-color,15%);
      }
    }
  }
}
