div.obis-site-cdisplay {
  display: block;
  width: 100%;
  
  .inner {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
  }

  &.invalid {
    .inner {
      .content {
        padding: 25px 15px;
        font-size: 14pt;
        font-style: italic;
        opacity: 0.88;
      }
    }
  }

  &.type-media {
    .inner {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:not(.invalid) .inner .content {
      // no content for media ads
      display: none;
    }
  }

  &.type-code, &.invalid {
    .inner {
      overflow: hidden;

      .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }

  &.type-code {
    .inner {
      .content {
        text-align: center;
        
        > * {
          margin: 0 auto;
        }
      }
    }
  }

  &.invalid {
    .inner {
      .content {
        height: 100%;
      }
    }
  }

  &.format-banner {
    .inner {
      padding-top: 640px / 1140px * 100%;
    }
  }

  &.format-leaderbanner,
  &.format-leaderbanner-footer {
    .inner {
      padding-top: 600px / 1920px * 100%;
    }
  }
  
  &.format-tile {
    max-width: 360px;
    margin: 0 auto;

    .inner {
      padding-top: 100%; // tile format is 1:1 (360x360, height variable as per contract)
      background-position-y: top; // align non 1:1 ads to the top of the element

      &.invalid {
        .content {
          font-size: 12pt;
        }
      }
    }
  }
}