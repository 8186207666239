header {
  .swiper-container {
    overflow: hidden;
    height: 100vh;

    @media (min-width: $screen-sm-min) {
      max-height: 600px;
      height: auto;
    }

    .swiper-pagination-bullet {
      background-color: transparent;
      border: 1px solid white;
      opacity: 1;
      transition: background-color 0.3s ease-in-out;

      &.swiper-pagination-bullet-active {
        background-color: white;
      }
    }

    .swiper-slide img {
      width: 100%;
    }
  }
}
