.slider-ad {

  & > a {
    .slick-wrapper {
      max-width: 500px;
      margin: 0 auto;

      .slick-list {
        .slick-track {
          .slick-slide {
            img {
              max-width: 100%;
              height: auto;
              width: auto;
            }
          }
        }
      }
    }
  }
}
