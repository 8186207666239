.advertisement-area {
  position: relative;

  background: $advertisement-area-background;
  box-shadow: inset 0 0 10px -1px $lightgrey;

  margin: 10px 0;
  max-height: 60vh;

  @media (min-width: $screen-sm-min) {
    max-height: 300px;
  }

  & > .container.no-padding {
    padding: 20px 0;

    & > .neos-contentcollection {
      text-align: center;

      & > .slick-list {
        overflow: hidden;

        & > .slick-track > .slick-slide {
          width: 100%;
          overflow: hidden;

          vertical-align: bottom;
          padding: 5px 10px;

          @media (min-width: $screen-xs-min) {
            width: 50%;
          }

          @media (min-width: $screen-sm-min) {
            width: 33%;
          }

          @media (min-width: $screen-md-min) {
            width: 25%;
          }

          @media (min-width: $screen-lg-min) {
            width: 20%;
          }
        }
      }
    }
  }
}
