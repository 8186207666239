.gallery {
  .gallery-item {
    padding: 10px;
    float: left;
    .inner {
      position: relative;
      overflow: hidden;

      img {
        filter: blur(0px);
        transform: scale(1);
        transition: transform 0.3s ease-in-out, filter 0.2s linear;
      }

      &::after {
        @include glyphicons('\E028');

        opacity: 0;

        position: absolute;
        top: calc(50% - 0.5em);
        left: calc(50% - 0.5em);
        font-size: 50px;
        color: white;
        text-shadow: 0 0 10px $text-color;
        pointer-events: none;

        transition: opacity 0.3s ease-in-out;
      }
    }

    &:hover {
      .inner {
        img {
          transform: scale(1.05);
          filter: blur(3px);

          transition: transform 0.3s ease-in-out, filter 0.2s linear 0.1s;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    figcaption {
      display: none;
    }
  }
}
